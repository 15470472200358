.sect4{
    height: 1238px;
    background-color: white;
}

.sec4subheading{
font-size: 22px;
font-weight: 400;
line-height: 40px;
letter-spacing: 0.2em;
text-align: left;
max-width: 428px;
font-family: "Jomolhari-Regular";
}

.sec4heading{
    font-family: "Jomolhari-Regular";
    font-size: 34px;
    font-weight: 400;
    line-height: 60px;
    letter-spacing: 0.2em;
    text-align: left;     
    max-width: 500px;
}

.sect4headingmain{
    font-family: "Jomolhari-Regular";
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0.2em;
    text-align: left;     
}

.sect4subheadingmain{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 20px;
max-width: 372px;

letter-spacing: 0.2em;

color: #C28562;
}

.img01{
    background-image: url("../images/h1-apart-feature-5.jpeg");
    width: 439px;
height: 338px;
background-size: 150%;
background-repeat: no-repeat;
background-position: top;
}

.img2{
    background-image: url("../images/h1-apart-feature-6.jpeg");
    width: 439px;
    height: 338px;
    background-size: 150%;
background-repeat: no-repeat;
background-position: top;
}

@font-face {
    font-family: "Jomolhari-Regular";
    src: local("Jomolhari-Regular.ttf"),
     url("../Fonts/Jomolhari/Jomolhari-Regular.ttf") format("truetype");
    font-weight: bold;
    }
    