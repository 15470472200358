.banner-image{
    background-image: url("../images//mainbanner.png");
}

.navtxt{


font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 36px;
color: #FFFFFF;
}

.navbg{
    background-color: rgba(0, 0, 0, 0.5)
}

.background {
    background-color: rgba(14, 14, 14, 0.7);

}

