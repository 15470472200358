/* .res{
    font-size: 160px;
    font-family: "Jomolhari-Regular";
    color: white;
}

.subheading{
font-size: 24px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0.5em;
text-align: center;
max-width: 800px;
font-family: "Jomolhari-Regular";
color:  #FF7E07;
}

.bgimg{
    background-image: url("../images/home1.jpeg");
    background-repeat: no-repeat;
    background-size: 100%;
    height: 900px;

} */

@media screen and (min-width:590px) and (max-width: 767px){
    .headingmainbanner {
        font-size: 75px !important;
    }}
    
    /* @media screen and (max-width: 326px){
        .headingaboutus {
            font-size: 60px !important;
        }} */

.headingmainbanner{
    font-family: 'Jomolhari-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 120px;
    line-height: 256px;
    text-align: center;
    margin: auto;
    color: #FFFFFF;
    }

    .subheadingmainbanner{
        letter-spacing: 0.5em;
    max-width: 800px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    
    color: #FF7E07;
    }

.mainbanner{
    background-image: linear-gradient(180deg, rgba(24, 24, 24, 0.3) 0%, rgba(48, 73, 136, 0.024) 100%), url("../images//mainbanner.png");
    background-size: cover;
    overflow: hidden;
}


@media screen and (min-width:460px) and (max-width: 590px){
    .headingmainbanner {
        font-size: 70px !important;
    }}

    @media screen and (min-width:375px) and (max-width: 459px){
        .headingmainbanner {
            font-size: 50px !important;
        }}

.padmainbanner{
    padding-top: 200px;
    padding-bottom: 200px;
    
}

@font-face {
    font-family: "Jomolhari-Regular";
    src: local("Jomolhari-Regular.ttf"),
     url("../Fonts/Jomolhari/Jomolhari-Regular.ttf") format("truetype");
    font-weight: bold;
    }