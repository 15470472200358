.sect3{
    background-image: url("../images/seamlessgold.jpg");
    height: 1177px ;
    opacity: 0.7;
}

.img1{
    border-radius: 50%;
    background-image: url("../icons/icon1.png");
    background-repeat: no-repeat;
    width: 162px;
    height: 162px;
    background-position: center center;
    background-color: transparent;
    border: 2px #C28562 solid;
}

.img002{
    background-image: url("../images/sec302.png");
    border-radius: 50%;
    background-repeat: no-repeat;
    width: 162px;
    height: 162px;
    background-position: center center;
    background-color: transparent;
    border: 2px #C28562 solid;
}

.img003{
    background-image: url("../images/sec303.png");
    border-radius: 50%;
    background-repeat: no-repeat;
    width: 162px;
    height: 162px;
    background-position: center center;
    background-color: transparent;
    border: 2px #C28562 solid;
}

.img004{
    background-image: url("../images/sec304.png");
    border-radius: 50%;
    background-repeat: no-repeat;
    width: 162px;
    height: 162px;
    background-position: center center;
    background-color: transparent;
    border: 2px #C28562 solid;
}

.img005{
    background-image: url("../images/sec305.png");
    border-radius: 50%;
    background-repeat: no-repeat;
    width: 162px;
    height: 162px;
    background-position: center center;
    background-color: transparent;
    border: 2px #C28562 solid;
}

.img006{
    background-image: url("../images/sec306.png");
    border-radius: 50%;
    background-repeat: no-repeat;
    width: 162px;
    height: 162px;
    background-position: center center;
    background-color: transparent;
    border: 2px #C28562 solid;
}

.content1{
    font-style: normal;
font-weight: 400;
font-size: 24px;
letter-spacing: 0.2em;
text-align: center;
color: #FFFFFF;
font-family: "Jomolhari-Regular";
}

.subcontent1{
    font-size: 18px;
    max-width: 343px;
    font-style: normal;
font-weight: 400;
font-size: 18px;
text-align: center;
color: #D1D1D1;
font-family: "Jomolhari-Regular";
}


@font-face {
    font-family: "Jomolhari-Regular";
    src: local("Jomolhari-Regular.ttf"),
     url("../Fonts/Jomolhari/Jomolhari-Regular.ttf") format("truetype");
    font-weight: bold;
    }

.sect3bgimage{
    background-size: cover;
    overflow: hidden;
    background-image: url("../images/seamlessgold.jpg");
}