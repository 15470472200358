.sect9col{
    background-color:  #C28562;
}

.s9font{
    font-family: 'Jomolhari-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    letter-spacing: 0.2em;
    color: #ffffff;

}

.user{
    background-color: #C28562;
    border: none;
    border-bottom: 1px solid white;
    display: block;
    width: 288px;
}

::-webkit-input-placeholder { 
    color: white;
    font-family: 'Jomolhari-Regular';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 30px;
letter-spacing: 0.2em;

  }

.btnborder{
width: 294px;
height: 44px;

background: #FFFFFF;
border-radius: 2px;
}

.btntxt{
    font-family: 'Jomolhari-Regular';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 30px;
letter-spacing: 0.2em;
color: #C28562;
}


/* .sect9height{
    height: 905px;
} */

@font-face {
    font-family: "Jomolhari-Regular";
    src: local("Jomolhari-Regular.ttf"),
     url("../Fonts/Jomolhari/Jomolhari-Regular.ttf") format("truetype");
    font-weight: bold;
    }

.sect101bcolor{

    background-color: #C28562;

} 