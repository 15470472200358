

.sect7img{
    border: 1px solid #ffffff;
    align-items: center;
}

.sect7image{
    height: 44%;
    width: 44%;
}

.sect7{
    background: #C28562;
}