body{
    background-color: #f5f5f5;
}

.contaboutus{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 36px;
padding-top: 10px;
text-align: center;
color: #313131;
}

/* sect2circle */

.mt-100{
    margin-top: 50px;
  }
  .progress {
    width: 150px;
    height: 150px !important;
    float: left; 
    line-height: 150px;
    background: none;
    margin: 20px;
    box-shadow: none;
    position: relative;
  }
  .progress:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 12px solid #D9D9D9;
    position: absolute;
    top: 0;
    left: 0;
  }
  .progress>span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }
  .progress .progress-left {
    left: 0;
  }
  .progress .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 12px;
    border-style: solid;
    position: absolute;
    top: 0;
  }
  .progress .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
  }
  .progress .progress-right {
    right: 0;
  }
  .progress .progress-right .progress-bar {
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
    animation: loading-1 1.8s linear forwards;
  }
  .progress .progress-value {
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    font-size: 24px;
    color: rgb(0, 0, 0);
    line-height: 135px;
    text-align: center;
    position: absolute;
    top: 5%;
    left: 5%;
  }
  .progress.blue .progress-bar {
    border-color: #000000;
  }
  .progress.blue .progress-left .progress-bar {
    animation: loading-2 1.5s linear forwards 1.8s;
  }
  .progress.yellow .progress-bar {
    border-color: #000000;
  }
  .progress.yellow .progress-right .progress-bar {
    animation: loading-3 1.8s linear forwards;
  }
  .progress.yellow .progress-left .progress-bar {
    animation: loading-6 1.8s linear forwards;
  }
  @keyframes loading-1 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
  @keyframes loading-2 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(144deg);
      transform: rotate(144deg);
    }
  }

  .progress.red .progress-bar {
    border-color: #000000;
  }
  .progress.red .progress-right .progress-bar {
    animation: loading-4 1.8s linear forwards;
  }
  .progress.red .progress-left .progress-bar {
    animation: loading-5 1.8s linear forwards;
  }
  @keyframes loading-3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    
  }
  @keyframes loading-4 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }

  @keyframes loading-5 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(100deg);
      transform: rotate(100deg);
    }
  }

  @keyframes loading-6 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(70deg);
      transform: rotate(70deg);
    }
  }

  .progress.green .progress-right .progress-bar {
    animation: loading-7 1.8s linear forwards;
  }
  .progress.green .progress-left .progress-bar {
    animation: loading-8 1.8s linear forwards;
  }

  .progress.green .progress-bar {
    border-color: #000000;
  }

  @keyframes loading-7 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
  @keyframes loading-8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(144deg);
      transform: rotate(155deg);
    }
  }
.sec2circleimg{
    background-image: url("../images/Ellipse.png");
    height: 50px;
    width: 50px;
}

/* .headaboutus{
font-family: 'Pridi';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 60px;
letter-spacing: 0.1em;
color: #000000;
} */

.sect2headingaboutus{

font-family: 'Pridi';
font-style: normal;
font-weight: 700;
font-size: 24px;
padding-top: 30px;

/* identical to box height, or 250% */
text-align: center;

letter-spacing: 0.1em;

color: #000000;
}

.sect3aboutus{
    max-width: 374px;
font-family: 'Jomolhari';
font-style: normal;
font-weight: 400;
font-size: 48px;
line-height: 60px;
color: #000000;
}

.sec4bgcolabus{
  background-color: #F4F3F2;;
}

.sect3aboutus2{
    max-width: 502px;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 36px;
}

.numtxt{
font-family: 'Jomolhari-Regular';
font-style: normal;
font-weight: 400;
font-size: 120px;
color: rgba(194, 133, 98, 0.5);
text-align: center;
}

@font-face {
    font-family: "Jomolhari-Regular";
    src: local("Jomolhari-Regular.ttf"),
     url("../Fonts/Jomolhari/Jomolhari-Regular.ttf") format("truetype");
    font-weight: bold;
    }

.aboutusmainimg{
    background-image:linear-gradient(180deg, #181818 0%, rgba(48, 73, 136, 0.08) 100%), url("../images/Aboutus.png");
    background-size: cover;
    overflow: hidden;

}
    
.headingaboutus{
    
max-width: 651px;
font-family: 'Jomolhari-Regular';
font-style: normal;
font-weight: 400;
font-size: 100px;
line-height: 256px;
text-align: center;
margin: auto;
color: #FFFFFF;
}

.subheadingaboutus{
    letter-spacing: 0.5em;
max-width: 800px;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 36px;
text-align: center;
margin-left: auto;
margin-right: auto;

color: #FF7E07;
}

.padaboutus{
    padding-top: 200px;
    padding-bottom: 200px;
    
}

@media screen and (min-width:326px) and (max-width: 527px){
.headingaboutus {
    font-size: 70px !important;
}}

@media screen and (max-width: 326px){
    .headingaboutus {
        font-size: 60px !important;
    }}

.thumbnail{
    background-image: url("../images/vidimg.png");
    background-size: cover;
    overflow: hidden;
}

.playbutton{
    background-color: white;
    border-radius: 50%;
    height: 144px;
    width: 144px;
}

.padthumbnail{
    padding-top: 260px;
    padding-bottom: 260px;
}

.sect6aboutus{
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 56px;
letter-spacing: 0.1em;
color: rgba(0, 0, 0, 0.3);

}


.cont6aboutus{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    padding-top: 10px;
    color: #313131;
    }


 .sect6headingaboutus{
        font-family: 'Pridi';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        padding-top: 30px;
        letter-spacing: 0.1em;
  color: #000000;
 }

 .s7h{
    max-width: 669px;
font-family: 'Jomolhari-Regular';
font-style: normal;
font-weight: 400;
font-size: 32px;
letter-spacing: 0.1em;
color: #FFFFFF;
 }

 .s7sh{
    color: white;
    font-size: 12px;
    max-width: 350px;
 }

 .btnborderaboutus{
    width: 250px;
    height: 44px;
    
    background: #FFFFFF;
    border-radius: 2px;
    }
    
    .btntxtaboutus{
        font-family: 'Jomolhari-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.2em;
    color: #C28562;
    }

    .dcontents{
        display: contents;
    }

 /* .sect7heading{
max-width: 669px;
font-family: 'Jomolhari-Regular';
font-style: normal;
font-weight: 400;
font-size: 48px;
letter-spacing: 0.1em;
color: #FFFFFF;
}

.sect7subheading{
position: absolute;
max-width: 669px;
height: 108px;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 18px;
color: #FFFFFF;
} */

.sect7bgcol{
    background-color: #C28562;
}


.sect8headingaboutus{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 30px;
line-height: 36px;
color: #FFFFFF;
text-align: left;
}

.sect8subheading{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 36px;
padding-top: 10px;
text-align: left;
color: #C28562; 
}

.bgsect8color{
    background-color: #171717;
}

.sect8headingmain{
font-family: 'Jomolhari-Regular';
font-style: normal;
font-weight: 400;
font-size: 48px;
color: #FFFFFF;
text-align: center;
}

.sect8subheadingmain{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 36px;
text-align: center;
color: rgba(255, 255, 255, 0.7);
}

.CircularProgressbar-path {
  stroke: red;
}
.CircularProgressbar-trail {
  stroke: gray;
}
.CircularProgressbar-text {
  fill: yellow;
}
.CircularProgressbar-background {
  fill: green;
}