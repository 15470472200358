body{
    background-color: white;
}

.sect2bannerheading{
font-size: 36px;
font-weight: 400;
line-height: 60px;
letter-spacing: 0.2em;
text-align: left;
max-width: 500px;
font-family: "Jomolhari-Regular";
}

.sect2img{
    width: 780px;
    height: 800px;
}

.sect2bannersubheading{
font-size: 24px;
font-weight: 400;
line-height: 40px;
letter-spacing: 0.2em;
text-align: center;
max-width: 428px;
font-family: "Jomolhari-Regular";
color: #C28562;
}

.sect2img div{
    height: 580px;
    width: 500px;
}

.sect2img :nth-child(1) {
    background-image: url("../images/apart-img-1.jpeg");
    top: 0;
    left: 0;
}

.sect2img :nth-child(2) {
    background-image: url("../images/p-list-img-13.jpeg");
    bottom: 0;
    right: 0;
    background-position: center center;
}

@font-face {
    font-family: "Jomolhari-Regular";
    src: local("Jomolhari-Regular.ttf"),
     url("../Fonts/Jomolhari/Jomolhari-Regular.ttf") format("truetype");
    font-weight: bold;
    }


    @media screen and (min-width:360px) and (max-width: 478px){
        .sect2bannerheading {
            font-size: 30px !important;
        }}
