.footcolor{
    background-color:  #171717;

}

.footerfont1{
    font-size:24px;
    color: #FFFFFF;
}

.footerfont2{
font-size: 16px;

text-decoration: none;
color: #FFFFFF;
}

.footerfont2:hover{
    cursor: pointer;
}



.footerlogosub{

    font-size: 16px;
max-width: 240px;

color: #FFFFFF;

}

