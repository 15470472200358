.houses{
    width: 387px;
height: 494px;

background: #F4F3F2;
}

.map{
    background-image: url("../images/map.png");
    max-width: 583px;
height: 389px;
background-repeat: no-repeat;
background-position: 100%;
}


.cont{

    max-width: 168px;
    font-family: 'Jomolhari-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 60px;
    
    
    letter-spacing: 0.2em;
    
    color: #000000;
    }
    
    @font-face {
        font-family: "Jomolhari-Regular";
        src: local("Jomolhari-Regular.ttf"),
         url("../Fonts/Jomolhari/Jomolhari-Regular.ttf") format("truetype");
        font-weight: bold;
        }